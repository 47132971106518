import { ChartWrapper } from 'components/charts/ChartWrapper';
import { Chart } from 'components/charts/Chart';
import { useTranslation } from 'react-i18next';
type Props = {
  isLoading: boolean;
};
export function CostBenefitAnalysis({
  isLoading
}: Props) {
  const {
    t
  } = useTranslation();
  return <ChartWrapper title={t('cost-benefit-analysis')} isLoading={isLoading} data-sentry-element="ChartWrapper" data-sentry-component="CostBenefitAnalysis" data-sentry-source-file="CostBenefitAnalysis.tsx">
      <Chart data-sentry-element="Chart" data-sentry-source-file="CostBenefitAnalysis.tsx" />
    </ChartWrapper>;
}